import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const ProdutosEditPage = (props) => {
    const app = useApp();
    // form validation schema
    const validationSchema = yup.object().shape({
        nome: yup.string().required().label("Nome"),
        //nomereduzido: yup.string().required().label("Nome Reduzido"),
        grupoid: yup.string().required().label("Grupo"),
        setorid: yup.string().required().label("Setor"),
        ean: yup.string().required().label("EAN (BARRAS)"),
        codexterno: yup.string().required().label("Cod Externo"),
        codfabrica: yup.string().required().label("Cod. Fabr"),
        unidade: yup.string().required().label("UND"),
        //ca: yup.string().required().label("Certificado de Aprovação (CA)"),
        validade: yup.string().required().label("Validade"),
        custo: yup.number().required().label("Custo"),
        venda: yup.number().required().label("Venda"),
        estoque: yup.number().required().label("Estoque"),
        estoquelocal: yup.number().required().label("Estoquelocal"),
        active: yup.string().required().label("Status"),
        integracao: yup.string().required().label("Integracao"),
        foto: yup.string().nullable().label("Foto")
    });
    // form default values
    const formDefaultValues = {
        nome: '',
        nomereduzido: '',
        grupoid: '',
        setorid: '',
        ean: '',
        codexterno: '',
        codfabrica: '',
        unidade: "UN",
        ca: '',
        validade: new Date(),
        custo: "0",
        venda: "0",
        estoque: "0",
        active: '',
        integracao: '',
        foto: '',
    }

    //where page logics resides
    const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
    //destructure and grab what we need
    const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
    //Event raised on form submit success
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if (props.redirect) {
            app.navigate(`/produtos`);
        }
    }
    // loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }
    //display error page 
    if (apiRequestError) {
        return (
            <PageRequestError error={apiRequestError} />
        );
    }
    //page is ready when formdata loaded successfully
    if (pageReady) {
        return (
            <main id="ProdutosEditPage" className="main-page">
                {(props.showHeader) &&
                    <section className="page-section mb-3" >
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage &&
                                    <div className="col-fixed " >
                                        <Button onClick={() => app.navigate(-1)} label="" className="p-button p-button-text " icon="pi pi-arrow-left" />
                                    </div>
                                }
                                <div className="col " >
                                    <Title title="Editar Produto" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section className="page-section " >
                    <div className="container">
                        <div className="grid ">
                            <div className="md:col-9 sm:col-12 comp-grid" >
                                <div >
                                    <Formik
                                        initialValues={formData}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, actions) => {
                                            submitForm(values);
                                        }
                                        }
                                    >
                                        {(formik) => {
                                            return (
                                                <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                                    <div className="grid">
                                                        <div className="md:col-8 col-12">
                                                            Nome *
                                                            <div>
                                                                <InputText disabled={app.auth.user.prodintegra} name="nome" onChange={formik.handleChange} value={formik.values.nome} label="Nome" type="text" placeholder="Entrar Nome" className={inputClassName(formik?.errors?.nome)} />
                                                                <ErrorMessage name="nome" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Nome Reduzido *
                                                            <div>
                                                                <InputText disabled={app.auth.user.prodintegra} name="nomereduzido" onChange={formik.handleChange} value={formik.values.nomereduzido} label="Nome Reduzido" type="text" placeholder="Entrar Nome Reduzido" className={inputClassName(formik?.errors?.nomereduzido)} />
                                                                <ErrorMessage name="nomereduzido" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            Grupo *
                                                            <div>
                                                                <DataSource apiPath="components_data/grupoid_option_list_2"  >
                                                                    {
                                                                        ({ response }) =>
                                                                            <>
                                                                                <Dropdown disabled={app.auth.user.prodintegra} name="grupoid" optionLabel="label" optionValue="value" value={formik.values.grupoid} onChange={formik.handleChange} options={response} label="Grupo" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.grupoid)} />
                                                                                <ErrorMessage name="grupoid" component="span" className="p-error" />
                                                                            </>
                                                                    }
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            Setor *
                                                            <div>
                                                                <DataSource apiPath="components_data/setorid_option_list"  >
                                                                    {
                                                                        ({ response }) =>
                                                                            <>
                                                                                <Dropdown disabled={app.auth.user.prodintegra} name="setorid" optionLabel="label" optionValue="value" value={formik.values.setorid} onChange={formik.handleChange} options={response} label="Setor" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.setorid)} />
                                                                                <ErrorMessage name="setorid" component="span" className="p-error" />
                                                                            </>
                                                                    }
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            EAN (BARRAS) *
                                                            <div>
                                                                <InputText disabled={app.auth.user.prodintegra} name="ean" onChange={formik.handleChange} value={formik.values.ean} label="EAN (BARRAS)" type="text" placeholder="Entrar EAN (BARRAS)" className={inputClassName(formik?.errors?.ean)} />
                                                                <ErrorMessage name="ean" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-3 col-12">
                                                            Cod Externo *
                                                            <div>
                                                                <InputText name="codexterno" onChange={formik.handleChange} value={formik.values.codexterno} label="Cod Externo" type="text" placeholder="Entrar Cod Externo" className={inputClassName(formik?.errors?.codexterno)} />
                                                                <ErrorMessage name="codexterno" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-3 col-12">
                                                            Cod. Fabr *
                                                            <div>
                                                                <InputText disabled={app.auth.user.prodintegra} name="codfabrica" onChange={formik.handleChange} value={formik.values.codfabrica} label="Cod. Fabr" type="text" placeholder="Entrar Cod. Fabr" className={inputClassName(formik?.errors?.codfabrica)} />
                                                                <ErrorMessage name="codfabrica" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-2 col-12">
                                                            UND *
                                                            <div>
                                                                <InputText disabled={app.auth.user.prodintegra} name="unidade" onChange={formik.handleChange} value={formik.values.unidade} label="UND" type="text" placeholder="Entrar UND" className={inputClassName(formik?.errors?.unidade)} />
                                                                <ErrorMessage name="unidade" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-8 col-12">
                                                            Certificado de Aprovação (CA) *
                                                            <div>
                                                                <InputText disabled={app.auth.user.prodintegra} name="ca" onChange={formik.handleChange} value={formik.values.ca} label="Certificado de Aprovação (CA)" type="text" placeholder="Entrar Certificado de Aprovação (CA)" className={inputClassName(formik?.errors?.ca)} />
                                                                <ErrorMessage name="ca" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Validade *
                                                            <div>
                                                                <Calendar disabled={app.auth.user.prodintegra} name="validade" showButtonBar className={inputClassName(formik?.errors?.validade)} dateFormat="yy-mm-dd" value={formik.values.validade} onChange={formik.handleChange} showIcon />
                                                                <ErrorMessage name="validade" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Custo *
                                                            <div>
                                                                <InputText disabled={app.auth.user.prodintegra} name="custo" onChange={formik.handleChange} value={formik.values.custo} label="Custo" type="number" placeholder="Entrar Custo" min={0} step={1} className={inputClassName(formik?.errors?.custo)} />
                                                                <ErrorMessage name="custo" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Venda *
                                                            <div>
                                                                <InputText disabled={app.auth.user.prodintegra} name="venda" onChange={formik.handleChange} value={formik.values.venda} label="Venda" type="number" placeholder="Entrar Venda" min={0} step={1} className={inputClassName(formik?.errors?.venda)} />
                                                                <ErrorMessage name="venda" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-2 col-12">
                                                            Estoque *
                                                            <div>
                                                                {/* <InputText disabled={app.auth.user.prodintegra} name="estoque" onChange={formik.handleChange} value={formik.values.estoque} label="Estoque" type="number" placeholder="Entrar Estoque" min={0} step={1} readonly className={inputClassName(formik?.errors?.estoque)} /> */}
                                                                <InputText name="estoque" onChange={formik.handleChange} value={formik.values.estoque} label="Estoque" type="number" placeholder="Entrar Estoque" min={0} step={1} readonly className={inputClassName(formik?.errors?.estoque)} />
                                                                <ErrorMessage name="estoque" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-2 col-12">
                                                            Estoque Cliente*
                                                            <div>
                                                                <InputText name="estoquelocal" onChange={formik.handleChange} value={formik.values.estoquelocal} label="Estoque" type="number" placeholder="Entrar Estoque" min={0} step={1} readonly className={inputClassName(formik?.errors?.estoquelocal)} />
                                                                <ErrorMessage name="estoquelocal" component="span" className="p-error" />
                                                            </div>
                                                        </div>                                                              
                                                        <div className="md:col-6 col-12">
                                                            Status *
                                                            <div>
                                                                <div className="flex flex-wrap">
                                                                    {
                                                                        app.menus.active2.map((option) => {
                                                                            return (
                                                                                <div key={option.value} className="field-radiobutton  mx-3">
                                                                                    <RadioButton disabled={app.auth.user.prodintegra} inputId={option.value} name="active" value={option.value} onChange={formik.handleChange} checked={formik.values.active === option.value} className={inputClassName(formik?.errors?.active, '')} />
                                                                                    <label htmlFor={option.value}>{option.label}</label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <ErrorMessage name="active" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            Integracao *
                                                            <div>
                                                                {
                                                                    app.menus.prodcad.map((option) => {
                                                                        return (
                                                                            <div key={option.value} className="field-radiobutton">
                                                                                <RadioButton disabled={app.auth.user.prodintegra} inputId={option.value} name="integracao" value={option.value} onChange={formik.handleChange} checked={formik.values.integracao === option.value} className={inputClassName(formik?.errors?.integracao, '')} />
                                                                                <label htmlFor={option.value}>{option.label}</label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                <ErrorMessage name="integracao" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            Foto
                                                            <div>
                                                                <div className={inputClassName(formik?.errors?.foto)}>
                                                                    <Uploader disabled={app.auth.user.prodintegra} name="foto" showUploadedFiles value={formik.values.foto} uploadPath="fileuploader/upload/foto" onChange={(paths) => formik.setFieldValue('foto', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Escolha arquivos ou solte-os aqui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                                </div>
                                                                <ErrorMessage name="foto" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.showFooter &&
                                                        <div className="text-center my-3">
                                                            <Button onClick={(e) => handleSubmit(e, formik)} type="submit" label="Atualizar" icon="pi pi-send" loading={saving} />
                                                        </div>
                                                    }
                                                </Form>
                                            );
                                        }
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
ProdutosEditPage.defaultProps = {
    primaryKey: 'id',
    pageName: 'produtos',
    apiPath: 'produtos/edit',
    routeName: 'produtosedit',
    submitButtonLabel: "Atualizar",
    formValidationError: "O formulário é inválido",
    formValidationMsg: "Por favor, preencha o formulário",
    msgTitle: "Atualizar registro",
    msgAfterSave: "Registro atualizado com sucesso",
    msgBeforeSave: "Deseja Salvar os Dados Agora?",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false
}
export default ProdutosEditPage;
