import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import DepartamentosViewPage from 'pages/departamentos/View';
import FuncionariosEditPage from 'pages/funcionarios/Edit';
import FuncoesViewPage from 'pages/funcoes/View';
import SetoresViewPage from 'pages/setores/View';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';

import useViewPage from 'hooks/useViewPage';
import MasterDetailPages from './MasterDetailPages';
const FuncionariosViewPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const pageController = useViewPage(props);
	const { item, currentRecord, pageReady, loading, apiRequestError, deleteItem } = pageController;
	function ActionButton(data){
		const items = [
		{
			label: "Editar",
			command: (event) => { app.openPageDialog(<FuncionariosEditPage isSubPage apiPath={`/funcionarios/edit/${data.id}`} />, {closeBtn: true }) },
			icon: "pi pi-pencil",
			visible: () => auth.canView('funcionarios/edit')
		},
		{
			label: "Excluir",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash",
			visible: () => auth.canView('funcionarios/delete')
		}
	]
	.filter((item) => {
		if(item.visible){
			return item.visible()
		}
		return true;
	});
		return (<Menubar className="p-0 " model={items} />);
	}
	function PageFooter() {
		if (props.showFooter) {
			return (
				<div className="flex justify-content-between">
	<div className="flex justify-content-start">
	{ActionButton(item)}
	</div>
				</div>
			);
		}
	}
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	if(pageReady){
		return (
			<div>
<main id="FuncionariosViewPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Visão"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="col comp-grid" >
                    <div >
                        <div className="grid ">
                            <div className="col">
                                {/*PageComponentStart*/}
                                <div className="mb-3 grid ">
                                    <div className="col-12 md:col-4">
                                        <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                            <div className="">
                                                <div className="text-400 font-medium mb-1">Id</div>
                                                <div className="font-bold">{ item.id }</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                            <div className="">
                                                <div className="text-400 font-medium mb-1">Nome</div>
                                                <div className="font-bold">{ item.nome }</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                            <div className="col-fixed">
                                                <Avatar size="large" icon="20%" />
                                                </div>
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Apelido</div>
                                                    <div className="font-bold">{ item.apelido }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Sexo</div>
                                                    <div className="font-bold">{ item.sexo }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Cpf</div>
                                                    <div className="font-bold">{ item.cpf }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Rg</div>
                                                    <div className="font-bold">{ item.rg }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Cracha</div>
                                                    <div className="font-bold">{ item.cracha }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Emissão do Cracha</div>
                                                    <div className="font-bold">{ item.crachaemissao }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Mail</div>
                                                    <div className="font-bold">{ item.mail }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Cep</div>
                                                    <div className="font-bold">{ item.cep }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Logradouro</div>
                                                    <div className="font-bold">{ item.logradouro }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Munid</div>
                                                    <div className="font-bold">{ item.munid }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Funcaoid</div>
                                                    <div className="font-bold">{item.funcaoid && <Button className="p-button-text" icon="pi pi-eye" label="Funcoes Detail" onClick={() => app.openPageDialog(<FuncoesViewPage isSubPage apiPath={`/funcoes/view/${item.funcaoid}`} />, {closeBtn: true })} /> }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Setorid</div>
                                                    <div className="font-bold">{item.setorid && <Button className="p-button-text" icon="pi pi-eye" label="Setores Detail" onClick={() => app.openPageDialog(<SetoresViewPage isSubPage apiPath={`/setores/view/${item.setorid}`} />, {closeBtn: true })} /> }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Deptid</div>
                                                    <div className="font-bold">{item.deptid && <Button className="p-button-text" icon="pi pi-eye" label="Departamentos Detail" onClick={() => app.openPageDialog(<DepartamentosViewPage isSubPage apiPath={`/departamentos/view/${item.deptid}`} />, {closeBtn: true })} /> }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Data de Admissão</div>
                                                    <div className="font-bold">{ item.dataadmissao }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Data de Demissão</div>
                                                    <div className="font-bold">{ item.datademissao }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Foto</div>
                                                    <div className="font-bold">{ item.foto }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*PageComponentEnd*/}
                                </div>
                                {
                                (currentRecord && !props.isSubPage) && 
                                <div className="col-12">
                                    <div className="card my-3 p-1">
                                        <MasterDetailPages masterRecord={currentRecord} scrollIntoView={false} />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
				<PageFooter />
			</div>
		);
	}
}
FuncionariosViewPage.defaultProps = {
	id: null,
	primaryKey: 'id',
	pageName: 'funcionarios',
	apiPath: 'funcionarios/view',
	routeName: 'funcionariosview',
	msgBeforeDelete: "Tem certeza de que deseja excluir este registro?",
	msgTitle: "Apagar registro",
	msgAfterDelete: "Registro excluído com sucesso",
	showHeader: true,
	showFooter: true,
	exportButton: true,
	isSubPage: false,
}
export default FuncionariosViewPage;
