/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {


  return {
    navbarTopRight: [],
    navbarTopLeft: [],
    navbarSideLeft: [
      {
        "to": "/home",
        "label": "Home",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/dashboard",
        "label": "Gerencial",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        "items": [
          {
            "to": "/empresas",
            "label": "Empresas",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "/dashboard",
            "label": "DashBoard",
            "icon": "pi pi-globe",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "/apuracao",
            "label": "Apurações",
            "icon": "pi pi-money-bill",
            "iconcolor": "",
            "target": "",
          }
        ]
      },
      {
        "to": "/funcoes",
        "label": "Cadastros",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        "items": [
          {
            "to": "/funcoes",
            "label": "Funcoes",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "/setores",
            "label": "Setores",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "/grupos",
            "label": "Grupos",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "/departamentos",
            "label": "Departamentos",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "/funcionarios",
            "label": "Funcionarios",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          }
        ]
      },
      {
        "to": "/produtos",
        "label": "Produtos / Estoque",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        "items": [

          {
            "to": "/produtos",
            "label": "Produtos",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "/inventario",
            "label": "Entradas/Saidas",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          },
          {
            "to": "/produtogrupos",
            "label": "Grupos de Produtos",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          }
        ]
      },
      {
        "to": "/kardex",
        "label": "Consulta",
        "icon": "pi pi-user",
        "iconcolor": "",
        "target": "",
        "items": [
          {
            "to": "/kardex",
            "label": "Ficha Kardex",
            "icon": "pi pi-th-large",
            "iconcolor": "",
            "target": "",
          }
        ]
      },
      
    ],
    tipo: [
      { value: "M", label: "Empresa" },
      { value: "F", label: "Cliente" }
    ],
    prodcad: [
      { value: "true", label: "Sim" },
      { value: "false", label: "Não" }
    ],
    active: [
      { value: "true", label: "Ativa" },
      { value: "false", label: "Inativa" }
    ],
    active2: [
      { value: "t", label: "Ativo" },
      { value: "f", label: "Inativo" }
    ],
    sexo: [
      { value: "M", label: "Másculino" },
      { value: "F", label: "Feminino" }
    ],
    active2: [
      { value: "true", label: "Ativo" },
      { value: "false", label: "Inativo" }
    ],
    tipo2: [
      { value: "E", label: "Entrada" },
      { value: "S", label: "Saida" }
    ],
    exportFormats: {
      print: {
        label: 'Print',
        icon: 'pi pi-print',
        type: 'print',
        ext: '',
      },
      pdf: {
        label: 'Pdf',
        icon: 'pi pi-file-pdf',
        type: 'pdf',
        ext: 'pdf',
      },
      excel: {
        label: 'Excel',
        icon: 'pi pi-file-excel',
        type: 'excel',
        ext: 'xlsx',
      },
      csv: {
        label: 'Csv',
        icon: 'pi pi-table',
        type: 'csv',
        ext: 'csv',
      },
    },
  }
}