import React, { useState, useEffect, useContext } from 'react';
import { Title } from 'components/Title';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import useApp from 'hooks/useApp';
import useApi from 'hooks/useApi';
import useUtils from 'hooks/useUtils';

export default function ApuracaoPage() {
    const app = useApp();
    const api = useApi();
    const utils = useUtils();
    const { empid, setempid } = useApp();

    const [pageReady, setPageReady] = useState(true);
    const [MotTipo, setMotTipo] = useState("T");
    const [DataI, setDataI] = useState(null);
    const [DataF, setDataF] = useState(null);
    const [TitlePage, setTitlePage] = useState("Apuração de Vendas");
    const [loading, setloading] = useState(false);
    const [disableFilter, setdisableFilter] = useState(false);
    const [disablePrint, setdisablePrint] = useState(true);
    const [TitleFilter, setTitleFilter] = useState("Filtrar");

    const [ApuraId, setApuraId] = useState(null);
    const [ApuraTipo, setApuraTipo] = useState("C"); // C = Consultar, A = Apurar
    const [DadosApuracao, setDadosApuracao] = useState([]);
    const [DadosApurados, setDadosApurados] = useState([]);
    const [DisableControls, setDisableControls] = useState(false);
    const [DisableControlFilter, setDisableControlFilter] = useState(false);


    const ListOperations = [
        { name: 'Processar Pedidos para Apuração', value: 'P' },
        { name: 'Consulta Apurações Geradas Anteriormente', value: 'C' }
    ];

    const ListMovimentacoes = [
        { label: 'Todos', value: 'T' },
        { label: 'Entrada', value: 'E' },
        { label: 'Saída', value: 'S' },
        // ... outras opções
    ];

    useEffect(() => {
        const hoje = new Date();
        const ano = hoje.getFullYear();
        const mes = hoje.getMonth();
        const primeiroDia = new Date(ano, mes, 1);
        setDataI(primeiroDia);
        const ultimoDia = new Date(ano, mes + 1, 0); // Dia 0 do próximo mês é o último dia do mês atual
        //setDataF(ultimoDia);
        setDataF(hoje);
    }, []);

    useEffect(() => {
        setDadosApuracao([]);
        setApuraId(null);
        setdisablePrint(true);
        GetApuradas();
    }, [empid]);

    useEffect(() => {
        setDisableControls(true);
        setDisableControlFilter(true);
        // Processar Apuracao
        if (ApuraTipo == "P") {
            setTitlePage("Apuração de Vendas");
            setTitleFilter("Localizar");
            setDisableControlFilter(false);
        }
        // Consultar Apurações
        else 
        {
            setTitleFilter("Mostrar");
            setDisableControlFilter(ApuraId == null);
            //GetApuradas();
        }
    }, [ApuraTipo]);

    useEffect(() => {
        setDisableControlFilter(ApuraId == null);
    }, [ApuraId]);

    const [filtro, setFiltro] = useState({
        produto: '',
        dataInicial: null,
        dataFinal: null,
        tipoMovimentacao: null,
    });

    function PageResume() {
        return (
            <div className="flex flex-wrap">
                <div>Prudutos : 0,00</div>
                <div>Total : 0,00</div>
            </div>
        );
    }

    function GetApuradas() {
        let apiPath = `Pedidos/apuracao-list/${empid}`;
        api.get(apiPath).then(response => { setDadosApurados(response.data) });
    }

    function PageFooter() {
        return (
            <div className="flex flex-wrap">
                <PageResume />
            </div>
        )
    }

    const onclickFilter = async (event) => {
        try {
            let response = null;
            setloading(true);
            if (ApuraTipo == "P") {
                const dataFormatada = DataF.toLocaleDateString('en-US');
                response = await api.get(`pedidos/apuracao?&datafinal=${dataFormatada}`);
            }
            else {
                response = await api.get(`pedidos/Apurado/${ApuraId}`);
            }

            let QtdeTotal = parseInt(response.data.resumo.itens);
            if (response.data != "undefined") {
                setDisableControls(QtdeTotal === 0);
                setDadosApuracao(response.data);
                setloading(false);
            }
            else 
            {
                setDisableControls(true);
                setDadosApuracao(null);
            }
        } catch (error) {
            console.clear();
            console.error(error);
        }
    }

    const onClickPrintApurar = async (e) => {
        if (ApuraTipo == "P")
        {
            await ApurarVendasAsync(e);
        }
        else 
        {
            await ImprimirApuracao(e);
        }
    }

    const OnClickPrintPedido = (param) => {
        if (param) {
            var paramns = { id: 2, filter: `pedido|id|${param.id}|0|0` };
            app.openPageDialog(<app.PrintPreview paramns={paramns} />, { maximized: true, closeBtn: true, width: '80vw' })
        }
    };

    const ApurarVendasAsync = async(e) => {
        try {
            let confirm = await app.dialog.Confirm('Processar Apuração', 'Deseja processar a apuração?');
            if (confirm == true) {
                setloading(true);
                let formPost = {
                    emp: empid,
                    datafinal: app.FmtDateEN(DataF)
                };
                let json = JSON.stringify(formPost);
                let response = await api.post("pedidos/apurar/", formPost);
                if (response.data == true) {
                    app.dialog.Info('Apuração Processada com Sucesso');
                    setDadosApuracao([]);
                    GetApuradas()
                }
                else {
                    app.dialog.Error('Erro ao realizar a apuração. Voce selecionou a Empresa Correta?');
                }
                setloading(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const ImprimirApuracao = async(param) => {
        try {
            let confirm = await app.dialog.Confirm('Imprimir Apuração', 'Imprimir a Apuração Selecionada ?');
            if (confirm == true) {
                var paramns = { id: 5, filter: `v_apuracao|id|${ApuraId}|0|0` };
                app.openPageDialog(<app.PrintPreview paramns={paramns} />, { maximized: true, closeBtn: true, width: '80vw' })
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    const templateOptionData = (option) => {
        return (
            <div>
                De&nbsp;<strong>{app.FmtDateBR(option.datai)}</strong>&nbsp;Até&nbsp;<strong>{app.FmtDateBR(option.dataf)}</strong>
                <br />Produtos &nbsp;<strong>{app.FmtQtd(option.itens)}</strong>&nbsp;Total R$&nbsp;<strong>{app.FmtCurr(option.total)}</strong>
            </div>
        );
    };

    function bodyUnit(data) {
        if (data) {
            return (
                <>{utils.currency(data.unit, 'BR', 2)}</>
            );
        }
    }

    function bodyUnit(data) {
        if (data) {
            return (
                <>{utils.currency(data.unit, 'BR', 2)}</>
            );
        }
    }

    function bodyDateTime(data) {
        if (data) {
            return (
                <>{app.FmtDateTimeBR(data.dt)}</>
            );
        }
    }

    function TemplatePrintPedido(data) {
        if (data) {
            let ftm = String(data.idimport).padStart(6, '0'); 
            return (<app.PrintButton label={ftm} onClick={() => OnClickPrintPedido(data)} />);
        }
    }

    function TemplatePedido(data) {
        if (data) {
            // Zero Left
            let ftm = String(data.idimport).padStart(6, '0'); 
            return ftm;
        }
    }
    


    function bodyTotal(data) {
        if (data) {
            return (
                <>{app.FmtCurr(data.total)}</>
            );
        }
    }

    function bodyQtde(data) {
        if (data) {
            return (
                <>{app.FmtQtd(data.qtde)}</>
            );
        }
    }

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">Filtragem</span>

            <div className="container">
                <div className="grid">

                    <div className="md: col">
                        <label>Selecione uma Operação</label>
                        <Dropdown style={{ width: '100%' }}
                            optionLabel='name'
                            optionValue='value'
                            value={ApuraTipo}
                            onChange={(e) => { setApuraTipo(e.value); }}
                            options={ListOperations}
                            placeholder="Operação" />
                    </div>

                    {(ApuraTipo === "C") ? (
                        <div className="md: col w-40">
                            <label style={{ display: 'inline-block', width: '300px' }}>Apurações Geradas Anteriormente</label>
                            <Dropdown style={{ width: '100%' }}
                                filter
                                itemTemplate={templateOptionData}
                                optionLabel="datai"
                                optionValue="id"
                                value={ApuraId}
                                onChange={(e) => { setApuraId(e.value); }}
                                options={DadosApurados}
                                label="Apurção Anterior"
                                placeholder="Apurações Geradas..."

                            />
                        </div>
                    ) : null}

                    {(ApuraTipo === "P") ? (

                        <div className="md: col pr-2">
                            <label style={{ display: 'inline-block', width: '300px' }}>Data Final do Ultimo Pedido Realizado</label>
                            <Calendar style={{ width: '100%' }}
                                dateFormat="dd/mm/yy"
                                placeholder="Data"
                                value={DataF}
                                onChange={(e) => setDataF(e.value)}
                            />
                        </div>
                    ) : null}

                    <div name="ButtonsDiv" className='grid mx-1'>
                        <div className="md: col">
                            <Button label={TitleFilter} disabled={DisableControlFilter} onClick={onclickFilter} icon="pi pi-refresh" style={{ width: '100%', marginTop: '15px' }} />
                        </div>

                        {(ApuraTipo === "P") ? (
                            <div className="md: col">
                                <Button label="Processar" disabled={DisableControls} onClick={onClickPrintApurar} icon="pi pi-refresh" style={{ width: '100%', marginTop: '15px' }} />
                            </div>
                        ) : null}


                        {(ApuraTipo === "C") ? (
                            <div className="md: col">
                                <Button label="Imprimir" disabled={DisableControls} onClick={onClickPrintApurar} icon="pi pi-print" style={{ width: '100%', marginTop: '15px' }} />
                            </div>
                        ) : null}

                    </div>
                </div>
                {(DadosApuracao.resumo != null) ? (
                    <div style={{ display: "inline" }} className="ml-2 md: col ">
                        <div className="grid ">
                            <div style={{ marginLeft: '10%' }}>
                                <div style={{ fontSize: '20px', align: 'right' }}>Produtos</div>
                                <div style={{ fontSize: '30px', align: 'right' }}>{
                                    (DadosApuracao.resumo.itens > 0) ? DadosApuracao.resumo.itens : 0}</div>
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                <div style={{ fontSize: '20px', align: 'right' }}>R$ Total</div>
                                <div style={{ fontSize: '30px', align: 'right' }}>{utils.currency(DadosApuracao.resumo.total, 'BR', 2)}</div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );

    return (
        <>
            <div style={{ marginBottom: '50px' }}>
                <h1>{TitlePage}</h1>
            </div>

            <div className="card">
                <DataTable
                    //scrollable 
                    //scrollHeight="400px"
                    header={header}
                    lazy={true}
                    value={DadosApuracao.detalhe}
                    loading={loading}
                    //paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                    //selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                    //value={records}
                    dataKey="id"
                    //sortField={sortBy}
                    //sortOrder={sortOrder}
                    //onSort={onSort}
                    className=" p-datatable-sm"
                    stripedRows={true}
                    showGridlines={false}
                    rowHover={true}
                    responsiveLayout="stack"
                //emptyMessage={<EmptyRecordMessage />}
                >
                    {/*PageComponentStart*/}
                    <Column headerStyle={{ width: '5%' }} field="idimport" body={TemplatePrintPedido} header="Pedido" sortable={true} ></Column>
                    <Column headerStyle={{ width: '15%' }} field="dt" body={bodyDateTime} header="Emissão" sortable={true} ></Column>
                    <Column headerStyle={{ width: '100%' }} field="produto" header="Produto" sortable={true} ></Column>
                    <Column headerStyle={{ width: '20%' }} field="Validade" header="Validade" sortable={true} ></Column>
                    <Column headerStyle={{ width: '5%' }} field="unidade" header="UND" ></Column>
                    <Column headerStyle={{ width: '20%' }} field="unit" header="Unit" body={bodyUnit} align={'right'} ></Column>
                    <Column headerStyle={{ width: '20%' }} field="qtde" header="Qtde" body={bodyQtde} align={'right'} ></Column>
                    <Column headerStyle={{ width: '20%' }} field="total" header="Total" body={bodyTotal} align={'right'} ></Column>
                    {/*PageComponentEnd*/}
                </DataTable>
            </div>


        </>
    );
}
