import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';
import useAddPage from 'hooks/useAddPage';

const FuncionariosAddPage = (props) => {
    const app = useApp();

    //form validation rules
    const validationSchema = yup.object().shape({
        nome: yup.string().required().label("Nome"),
        apelido: yup.string().nullable().label("Apelido"),
        sexo: yup.string().required().label("Sexo"),
        cpf: yup.string().min(11).min(11).required().label("CPF"),
        rg: yup.string().required().label("RG"),
        cracha: yup.string().required().label("Cracha"),
        crachaemissao: yup.string().required().label("Emissao Cracha"),
        cep: yup.string().required().label("CEP"),
        logradouro: yup.string().required().label("Logradouro"),
        munid: yup.string().nullable().label("Município"),
        funcaoid: yup.string().required().label("Função"),
        setorid: yup.string().required().label("Setor"),
        deptid: yup.string().required().label("Departamento"),
        grupoid: yup.string().required().label("Grupo"),
        mail: yup.string().email().required().label("Mail"),
        dataadmissao: yup.string().required().label("Dataadmissao"),
        datademissao: yup.string().required().label("Datademissao"),
        active: yup.string().required().label("Status"),
        foto: yup.string().nullable().label("Foto")
    });

    //form default values
    const formDefaultValues = {
        nome: '',
        apelido: '',
        sexo: '',
        cpf: '',
        rg: '',
        cracha: '',
        crachaemissao: new Date(),
        cep: '',
        logradouro: '',
        munid: '',
        funcaoid: '',
        setorid: '',
        deptid: '',
        mail: '',
        grupoid: '',
        dataadmissao: new Date(),
        datademissao: new Date(),
        active: '',
        foto: '',
    }

    // Submite Personalizado
    const OnClickSubmit = async (event, formik) => {
        console.log("Formik: ",formik,"\n\nEvent: ",event)
        handleSubmit(event);
        // handleSubmit(formik);
    }

    //page hook where logics resides
    const pageController = useAddPage({ props, formDefaultValues, afterSubmit });

    // destructure and grab what the page needs
    const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;

    //event raised after form submit
    function afterSubmit(response) {
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        resetForm();
        if (app.isDialogOpen()) {
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if (props.redirect) {
            app.navigate(`/funcionarios`);
        }
    }

    // page loading form data from api
    if (loading) {
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
            </div>
        );
    }

    //page has loaded any required data and ready to render
    if (pageReady) {
        return (
            <main id="FuncionariosAddPage" className="main-page">
                {(props.showHeader) &&
                    <section className="page-section mb-3" >
                        <div className="container">
                            <div className="grid justify-content-between align-items-center">
                                {!props.isSubPage &&
                                    <div className="col-fixed " >
                                        <Button onClick={() => app.navigate(-1)} label="" className="p-button p-button-text " icon="pi pi-arrow-left" />
                                    </div>
                                }
                                <div className="col " >
                                    <Title title="Adicionar novo" titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" separator={false} />
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section className="page-section " >
                    <div className="container">
                        <div className="grid ">
                            <div className="md:col-9 sm:col-12 comp-grid" >
                                <div >
                                    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) => submitForm(values)}>
                                        {(formik) =>
                                            <>
                                                <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                                    <div className="grid">
                                                        <div className="md:col-6 col-12">
                                                            Nome *
                                                            <div>
                                                                <InputText name="nome" onChange={formik.handleChange} value={formik.values.nome} label="Nome" type="text" placeholder="Entrar Nome" className={inputClassName(formik?.errors?.nome)} />
                                                                <ErrorMessage name="nome" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-3 col-12">
                                                            Apelido
                                                            <div>
                                                                <InputText name="apelido" onChange={formik.handleChange} value={formik.values.apelido} label="Apelido" type="text" placeholder="Entrar Apelido" className={inputClassName(formik?.errors?.apelido)} />
                                                                <ErrorMessage name="apelido" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-3 col-12">
                                                            Sexo *
                                                            <div>
                                                                <Dropdown name="sexo" optionLabel="label" optionValue="value" value={formik.values.sexo} onChange={formik.handleChange} options={app.menus.sexo} label="Sexo" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.sexo)} />
                                                                <ErrorMessage name="sexo" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-2 col-12">
                                                            MATRICULA
                                                            <div>
                                                                <InputText name="matricula" onChange={formik.handleChange} value={formik.values.matricula} label="CPF" type="text" placeholder="Entrar CPF" className={inputClassName(formik?.errors?.cpf)} />
                                                                <ErrorMessage name="matricula" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-2 col-12">
                                                            Cracha *
                                                            <div>
                                                                <InputText name="cracha" onChange={formik.handleChange} value={formik.values.cracha} label="Cracha" type="text" placeholder="Entrar Cracha" className={inputClassName(formik?.errors?.cracha)} />
                                                                <ErrorMessage name="cracha" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-3 col-12">
                                                            Emissao Cracha *
                                                            <div>
                                                                <Calendar name="crachaemissao" showButtonBar className={inputClassName(formik?.errors?.crachaemissao)} dateFormat="yy-mm-dd" value={formik.values.crachaemissao} onChange={formik.handleChange} showIcon />
                                                                <ErrorMessage name="crachaemissao" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-3 col-12">
                                                            CPF *
                                                            <div>
                                                                <InputText name="cpf" onChange={formik.handleChange} value={formik.values.cpf} label="CPF" type="text" placeholder="Entrar CPF" className={inputClassName(formik?.errors?.cpf)} />
                                                                <ErrorMessage name="cpf" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-2 col-12">
                                                            RG *
                                                            <div>
                                                                <InputText name="rg" onChange={formik.handleChange} value={formik.values.rg} label="RG" type="text" placeholder="Entrar RG" className={inputClassName(formik?.errors?.rg)} />
                                                                <ErrorMessage name="rg" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-3 col-12">
                                                            CEP *
                                                            <div>
                                                                <InputText name="cep" onChange={formik.handleChange} value={formik.values.cep} label="CEP" type="text" placeholder="Entrar CEP" className={inputClassName(formik?.errors?.cep)} />
                                                                <ErrorMessage name="cep" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-9 col-12">
                                                            Logradouro *
                                                            <div>
                                                                <InputText name="logradouro" onChange={formik.handleChange} value={formik.values.logradouro} label="Logradouro" type="text" placeholder="Entrar Logradouro" className={inputClassName(formik?.errors?.logradouro)} />
                                                                <ErrorMessage name="logradouro" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            Município
                                                            <div>
                                                                <DataSource apiPath="components_data/munid_option_list"  >
                                                                    {
                                                                        ({ response }) =>
                                                                            <>
                                                                                <Dropdown name="munid" optionLabel="label" optionValue="value" value={formik.values.munid} onChange={formik.handleChange} options={response} label="Município" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.munid)} />
                                                                                <ErrorMessage name="munid" component="span" className="p-error" />
                                                                            </>
                                                                    }
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Função *
                                                            <div>
                                                                <DataSource apiPath="components_data/funcaoid_option_list"  >
                                                                    {
                                                                        ({ response }) =>
                                                                            <>
                                                                                <Dropdown name="funcaoid" optionLabel="label" optionValue="value" value={formik.values.funcaoid} onChange={formik.handleChange} options={response} label="Função" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.funcaoid)} />
                                                                                <ErrorMessage name="funcaoid" component="span" className="p-error" />
                                                                            </>
                                                                    }
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Setor *
                                                            <div>
                                                                <DataSource apiPath="components_data/setorid_option_list"  >
                                                                    {
                                                                        ({ response }) =>
                                                                            <>
                                                                                <Dropdown name="setorid" optionLabel="label" optionValue="value" value={formik.values.setorid} onChange={formik.handleChange} options={response} label="Setor" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.setorid)} />
                                                                                <ErrorMessage name="setorid" component="span" className="p-error" />
                                                                            </>
                                                                    }
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Departamento *
                                                            <div>
                                                                <DataSource apiPath="components_data/deptid_option_list"  >
                                                                    {
                                                                        ({ response }) =>
                                                                            <>
                                                                                <Dropdown name="deptid" optionLabel="label" optionValue="value" value={formik.values.deptid} onChange={formik.handleChange} options={response} label="Departamento" placeholder="Selecione um valor ..." className={inputClassName(formik?.errors?.deptid)} />
                                                                                <ErrorMessage name="deptid" component="span" className="p-error" />
                                                                            </>
                                                                    }
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            Grupo *
                                                            <div>
                                                            <DataSource apiPath="components_data/grupoid_option_list"  >
                                                                    {
                                                                        ({ response }) =>
                                                                            <>
                                                                                <Dropdown name="grupoid" optionLabel="label" optionValue="value" value={formik.values.grupoid} onChange={formik.handleChange} options={response} label="Grupo" placeholder="Selecione o grupo ..." className={inputClassName(formik?.errors?.grupoid)}/>
                                                                                <ErrorMessage name="grupoid" component="span" className="p-error" />
                                                                            </>
                                                                    }
                                                                </DataSource>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            Mail *
                                                            <div>
                                                                <InputText name="mail" onChange={formik.handleChange} value={formik.values.mail} label="Mail" type="email" placeholder="Entrar Mail" className={inputClassName(formik?.errors?.mail)} />
                                                                <ErrorMessage name="mail" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Data Admissao*
                                                            <div>
                                                                <Calendar name="dataadmissao" showButtonBar className={inputClassName(formik?.errors?.dataadmissao)} dateFormat="yy-mm-dd" value={formik.values.dataadmissao} onChange={formik.handleChange} showIcon />
                                                                <ErrorMessage name="dataadmissao" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Data Demissao*
                                                            <div>
                                                                <Calendar name="datademissao" showButtonBar className={inputClassName(formik?.errors?.datademissao)} dateFormat="yy-mm-dd" value={formik.values.datademissao} 
                                                                onChange={
                                                                    formik.handleChange
                                                                } 
                                                                    showIcon />
                                                                <ErrorMessage name="datademissao" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="md:col-4 col-12">
                                                            Status *
                                                            <div>
                                                                <div className="flex flex-wrap">
                                                                    {
                                                                        app.menus.active2.map((option) => {
                                                                            return (
                                                                                <div key={option.value} className="field-radiobutton  mx-3">
                                                                                    <RadioButton inputId={option.value} name="active" value={option.value} onChange={formik.handleChange} checked={formik.values.active === option.value} className={inputClassName(formik?.errors?.active, '')} />
                                                                                    <label htmlFor={option.value}>{option.label}</label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                <ErrorMessage name="active" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            Foto
                                                            <div>
                                                                <div className={inputClassName(formik?.errors?.foto)}>
                                                                    <Uploader name="foto" showUploadedFiles value={formik.values.foto} uploadPath="fileuploader/upload/foto" onChange={(paths) => formik.setFieldValue('foto', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Escolha arquivos ou solte-os aqui" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                                </div>
                                                                <ErrorMessage name="foto" component="span" className="p-error" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.showFooter &&
                                                        <div className="text-center my-3">
                                                            <Button onClick={(e) => OnClickSubmit(e, formik)} className="p-button-primary" type="submit" label="Enviar" icon="pi pi-send" loading={saving} />
                                                        </div>
                                                    }
                                                </Form>
                                            </>
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

//page props and default values
FuncionariosAddPage.defaultProps = {
    primaryKey: 'id',
    pageName: 'funcionarios',
    apiPath: 'funcionarios/add',
    routeName: 'funcionariosadd',
    submitButtonLabel: "Enviar",
    formValidationError: "O formulário é inválido",
    formValidationMsg: "Por favor, preencha o formulário",
    msgTitle: "Criar registro",
    msgAfterSave: "Registro adicionado com sucesso",
    msgBeforeSave: "Deseja Salvar os Dados Agora?",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false
}
export default FuncionariosAddPage;
